/** @jsxImportSource @emotion/react */
import "style/global.css";
import Container from'./components/Container';
import { AuthProvider } from "hooks";
import AppContainer from "components/AppContainer";
import { useEffect } from "react";


function App() {
  /**
   * Polyfills
   */
  useEffect(() => {
    if (!Array.prototype.findLastIndex) {
      Array.prototype.findLastIndex = function (callback, thisArg) {
        for (let i = this.length - 1; i >= 0; i--) {
          if (callback.call(thisArg, this[i], i, this)) return i;
        }
        return -1;
      };
    }
  },[])

  return (
    <AuthProvider>
      <AppContainer>
        <Container />
      </AppContainer>
    </AuthProvider>
  );
}

export default App;