/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { color, fontSize } from "style";

/**
 * Product
 * This is the main container that holds the popup window and input 
 */
const Product = ({ children, ...props }) => (
  <button css={styles.button} {...props}>
    {children}
  </button>
)

const styles = {
  button: css`
    color:#fff;
    background: ${color.primary};
    border:0;
    border-radius: 15px;
    height:24px;
    padding:0 12px;
    font-size: ${fontSize.small} !important;
    font-weight:500;
    line-height: 24px;
    cursor:pointer;
    display:flex;
    align-items:center;
    gap:3px;
  `,
}

export default Product;
