import { useState, useEffect } from "react";
import { MOBILE_CUTOFF } from "constants";

function useViewport() {
  const [windowSize, setWindowSize] = useState({ 
    width: undefined, 
    height: undefined,
    isMobile: false
  });
  
  useEffect(() => {
    const handleResize = () => setWindowSize({ 
      width: window.innerWidth, 
      height: window.innerHeight,
      isMobile: window.innerWidth < MOBILE_CUTOFF
    });
    
    window.addEventListener("resize", handleResize);
    handleResize();
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return windowSize;
}

export default useViewport;