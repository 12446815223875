/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

/**
 * AppContainer
 */
const AppContainer = styled.section`
  letter-spacing:normal;
  line-height:normal;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  margin: 0;

  input, 
  textarea {
    font-family: 'Open Sans' !important;
    font-size:16px !important;
  }
`;

export default AppContainer;
