/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { color, fontSize } from "style";
import { DEFAULT_TEXT } from "constants";

/**
 * Title
 */
const Title = () => (
  <div css={styles.container}>
    <p css={styles.welcome}>
      {window.bigSurSettings?.title || DEFAULT_TEXT?.title}
    </p>
    <p css={styles.subtitle}>
      {window.bigSurSettings?.subtitle || DEFAULT_TEXT?.subtitle}
    </p> 
  </div>
);

const styles = {
  container: css`
    color:${color.primaryText};
    width:100%;
    border-radius:15px;
    padding:0px;
    box-sizing:border-box;
    width:80%;
    font-size:${fontSize.normal};
    margin-bottom:60px;
    margin-top: 60px;

    p {
      margin: 0 0 5px 0;
    }
  `,
  welcome: css`
    font-weight: 700;
    font-size:${fontSize.xlarge};
  `,
  subtitle: css`
    font-weight: 300;
    color: ${color.secondaryText};
  `
}

export default Title;