/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react";
import { ReactComponent as ImageClock } from 'assets/icon_clock.svg';
import { ReactComponent as ImageMore } from 'assets/icon_more.svg';
import { ReactComponent as ImageRightArrow } from 'assets/icon_rightarrow.svg';
import { ReactComponent as ImageLeftArrow } from 'assets/icon_leftarrow.svg';
import { ReactComponent as ImageSend } from 'assets/icon_send.svg';
import { ReactComponent as ImageReset } from 'assets/icon_reset.svg';
import { ReactComponent as ImageInfo } from 'assets/icon_info.svg';
import { ReactComponent as ImageInterruption } from 'assets/icon_interruption.svg';
import { ReactComponent as ImageClose } from 'assets/icon_close.svg';

const ICON_SIZE = {
  xsmall: "6px",
  small: "10px",
  medium: "14px",
  large: "18px",
  xlarge: "22px",
};

const ICON_TYPE = {
  settings: "settings",
  rightarrow: "rightarrow",
  leftarrow: "leftarrow",
  more: "more",
  checkmark: "checkmark",
  post: "post",
  close: "close",
  refresh: "refresh",
  clock: "clock",
  plus: "plus",
  menu: "menu",
  send: "send",
  reset: "reset",
  info: "info",
  interruption: "interruption"
};

const Icon = ({ type, size, color, ...props }) => {
  switch(type) {
    case ICON_TYPE.clock:
      return <ImageClock css={style(size, color)} {...props} />;
    case ICON_TYPE.more:
      return <ImageMore css={style(size, color)} {...props} />;
    case ICON_TYPE.rightarrow:
      return <ImageRightArrow css={style(size, color)} {...props} />;
    case ICON_TYPE.leftarrow:
      return <ImageLeftArrow css={style(size, color)} {...props} />;
    case ICON_TYPE.send:
      return <ImageSend css={style(size, color)} {...props} />;
    case ICON_TYPE.reset:
      return <ImageReset css={style(size, color)} {...props} />
    case ICON_TYPE.info:
      return <ImageInfo css={style(size, color)} {...props} />
    case ICON_TYPE.close:
      return <ImageClose css={style(size, color)} {...props} />
    case ICON_TYPE.interruption:
      return <ImageInterruption css={style(size, color)} {...props} />
    default:
      return <ImageClock css={style(size, color)} {...props} />;
  }
}

Icon.defaultProps = {
  size: ICON_SIZE.small,
  color: `#fff`
}

const style = (size, color) => css`
  width: ${size};
  height: ${size};
  fill: ${color};
`;

export { 
  ICON_TYPE, 
  ICON_SIZE 
};

export default Icon;
