import { MESSASE_ACTION_TYPES, MESSAGE_VALUES, MESSAGE_TYPES } from "constants";

const initialState = [];

/**
 * messageReducer
 * 
 * @param {Object}  state 
 * @param {Object}  action 
 * @returns {Object}
 */
const messageReducer = (state=initialState, action) => {
  switch (action.type) {
    case MESSASE_ACTION_TYPES.init: 
      return action.payload;

    case MESSASE_ACTION_TYPES.system: 
      const sliceIndex = state?.findLastIndex((message) => message.type === MESSAGE_TYPES.system && message?.action?.isReset === true);

      /**
       * If there's a previous marker and it's not the last message in our history
       * then delete all messages before this marker...
       */
      if ((sliceIndex !== -1) && (sliceIndex < (state.length - 1))) {
        return [
          ...state.slice(sliceIndex), 
          ...action.payload
        ];
      
      /**
       * ...otherwise if the index doesn't exist simply append the new marker
       */
      } else if (sliceIndex === -1) {
        return [
          ...state, 
          ...action.payload
        ];
      
      /**
       * Fall back (reset)
       * This won't trigger because either of the above will always be true 
       * based on how this action is triggered in useMessagePolling
       */
      } else {
        return initialState;
      }
    
    case MESSASE_ACTION_TYPES.query: 
      return [
        ...(state.map(message => ({
          ...message, 
          ...{ isInterrupted: message?.isInterrupted || message?.isFetching || false },
          ...{ isFetching: false }
        }))),
        ...action.payload
      ];
      
    case MESSASE_ACTION_TYPES.response: 
      return [
        ...state,
        ...action.payload
      ];

    case MESSASE_ACTION_TYPES.polling:
      return state.map((item) => {
        if (item.id === action?.payload?.i) {
          return { 
            ...item, 
            message: action?.payload?.er ? action?.payload?.er : item.message,
            isFetching: action?.payload?.l === MESSAGE_VALUES.continuePolling,
            meta: [
              ...(item.meta ? item.meta : []), 
              ...(action?.payload?.p ? action?.payload?.p : [])
            ]
          };
        } else {
          return item;
        }
      });
    default:
      return state;
  }
};

export default messageReducer;