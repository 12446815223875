/** @jsxImportSource @emotion/react */
import { useState } from "react";

/**
 * Shortens a string (ie: This is about...)
 *
 * @param {string} str the string to be shorten
 * @param {number} n shorten it to what point
 * @param {boolean} useWordBoundary use a word boundary or not
 *
 * @returns {string} the shorten string
 */
export default function ShortenText(str, n, useWordBoundary = true) {
  const [truncate, setTruncate] = useState(true);

  if (!truncate) return str;

  if (!str) {
    return;
  }

  if (str.length <= n) {
    return str;
  }

  const subString = str.substr(0, n);
  const shortenedString = useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) || subString : subString;

  return (
    <span>
      {shortenedString}
      <span onClick={() => setTruncate(false)}>…</span>
    </span>
  )
}
