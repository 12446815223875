/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { MOBILE_CUTOFF } from "constants";
import { color, fontSize } from "style";
import Icon, { ICON_TYPE } from "../Icon";

/**
 * SuggestionMessage
 * 
 * @param {String} message 
 * @param {String} onClick 
 */
const SuggestionMessage = ({ message, onClick }) => (
  <div css={styles.container} onClick={() => onClick(message)}>
    {message}
    <Icon 
      type={ICON_TYPE.rightarrow} 
      color={color.white}
    />
  </div>
);

const styles = {
  container: css`
    border-radius:15px;
    font-weight:600;
    padding:3px 0px 3px 0;
    box-sizing:border-box;
    max-width:100%;
    font-size:${fontSize.small};
    color: ${color.primary};
    display:flex;
    align-items:center;
    gap:5px;
    cursor:pointer;
    transition: none !important;

    @media only screen and (min-width: ${MOBILE_CUTOFF}px) {
      &:hover {
        color: ${color.primaryText};
      }
    }
  `,
}

export default SuggestionMessage;