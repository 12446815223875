import { useState, useEffect } from "react";

/**
 * useScrollDirection
 */
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState();
  const [scroll, setScroll] = useState({});
  const SCROLL_DOWN = "down";
  const SCROLL_UP = "up";
  const MAX = 250;
  const init = () => setScroll({});
  
  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const minVelocity = 5;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP;
      const tempDirection = scroll.y > scrollY ? SCROLL_UP : SCROLL_DOWN;

      setScrollDirection(direction);

      setScroll(prev => ({
        direction: tempDirection,
        origin: tempDirection !== prev.direction ? scrollY : prev.origin,
        marker: tempDirection !== prev.direction ? (prev.distance || 0) : prev.marker,
        distance: tempDirection !== prev.direction 
          ? 0 
          : Math.abs(prev.origin - scrollY) > MAX ? MAX : Math.abs(prev.origin - scrollY),
        y: scrollY,
        max: MAX
      }));
    }

    window.addEventListener("scroll", updateScrollDirection); // add event listener
    
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection, scroll, init]);

  return { 
    shouldShow: scrollDirection === SCROLL_UP, 
    scrollDirection, 
    scroll,
    init,
    SCROLL_DOWN, 
    SCROLL_UP 
  };
};

export default useScrollDirection;