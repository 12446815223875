/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { useAuth } from "hooks";
import { color, fontSize } from "style";

/**
 * Notification
 */
const Notification = ({ showWindow, ...props }) => {
  const { notificationsCount } = useAuth();

  if (!notificationsCount || showWindow) return null;

  return (
    <div css={styles.container} {...props}>
      {notificationsCount}
    </div>
  );
}

const styles = {
  container: css`
    color: ${color.white};
    background: ${color.red};
    font-size: ${fontSize.xxsmall};
    font-weight:700;
    border-radius:100%;
    width:15px;
    height:15px;
    display:flex;
    align-items:center;
    justify-content:center;
  `,
}

export default Notification;
