import { useEffect, useReducer, useState } from "react";
import { useAuth, useLocalStorage } from  "hooks";
import moment from "moment";
import { useGetResultByStringQuery, useResetConversationQuery, useUpdateResultQuery } from 'services/query';
import { messageReducer } from "reducers";
import { POLLING_INTERVAL, MESSASE_ACTION_TYPES, MESSAGE_VALUES, MESSAGE_TYPES, IDLE_MINUTES, DEFAULT_TEXT } from "constants";
import { generateId } from "utils";

const prompts = window.bigSurSettings?.prompts || DEFAULT_TEXT?.prompts;

const initialMessage = [{
  isQuery: false,
  isFetching: true,
  message: "Hello, and welcome to Foot Forward! How can I help you today?",
  typed:true,
  prompts,
  id: generateId(),
  meta: [],
  date: Date.now(),
  type: MESSAGE_TYPES.message,
}];

/**
 * useMessagePolling
 * 
 * @param {String}    userId     
 * @param {Boolean}   showWindow    
 */
function useMessagePolling({ userId, showWindow }) {
  const [storedValued, setValue] = useLocalStorage('messageHistory', initialMessage);
  const [messages, dispatch] = useReducer(messageReducer, storedValued);
  const [polling, setPolling] = useState(true)
  const [query, setQuery] = useState();
  const [lastMessage, setLastMessage] = useState({});
  const [id, setId] = useState(false);
  const { setNotifications } = useAuth();

  const { data: pollingData } = useUpdateResultQuery(({userId, id}), { 
    pollingInterval: POLLING_INTERVAL, 
    skip: !polling || !id
  });

  const { isFetching: isResetting } = useResetConversationQuery(userId, {
    skip: (
      (query?.toLowerCase() !== MESSAGE_VALUES.reset) && 
      (lastMessage?.type !== MESSAGE_TYPES.system) && 
      (lastMessage?.action?.isReset !== true)
    )
  })

  const { data, error, isLoading, isFetching } = useGetResultByStringQuery({query, userId, id}, {
    skip: (!query || query?.toLowerCase() === MESSAGE_VALUES.reset)
  })

  const handleQuery = q => {
    const tempId = generateId();

    setPolling(false);
    setId(tempId);
    setQuery(q);    

    switch(q) {
      case MESSAGE_VALUES.reset:
        dispatch( initQuery(initialMessage) );
        return;

      default:
        dispatch(
          addQuery([{
            isQuery: true,
            message: q,
            id: generateId(), 
            date: Date.now(),
            type: MESSAGE_TYPES.message,
          }])
        );
        dispatch(
          addResponse([{
            isQuery: false,
            isFetching: true,
            message: "",
            id: tempId,
            meta: [],
            date: Date.now(),
            type: MESSAGE_TYPES.message,
          }])
        );
        return;
    }
  }

  useEffect(() => {
    setLastMessage(messages?.length > 0 ? messages[messages.length - 1] : {});
  },[messages])

  useEffect(() => {
    if (showWindow) {
      if (lastMessage && lastMessage.type !== MESSAGE_TYPES.system) {
        const startTime = moment(lastMessage.date);
        const now = moment();
        const duration = moment.duration(now.diff(startTime));
        const minutesSince = duration.asMinutes();
        const idleMinutes = window?.bigSurSettings?.idle_minutes || IDLE_MINUTES;

        if (idleMinutes <= minutesSince) {
          dispatch(
            addSystemMessage([{              
              date: Date.now(),              
              type: MESSAGE_TYPES.system,
              action: { 
                isReset: true 
              }
            }])
          );
        }
      }
    }
  },[showWindow, lastMessage, window?.bigSurSettings]);

  useEffect(() => {
    if (!isFetching) {
      const incompleteMessage = messages.find((message) => message.isFetching);
      
      if (incompleteMessage) {
        setId(incompleteMessage?.id);
        setPolling(true);
      } else {
        setId(false);
        setPolling(false);
      }
    }
  },[messages, isFetching])

  useEffect(() => {
    setValue(messages);
  },[messages])
  
  useEffect(() => {
    dispatch(initQuery(storedValued));
  },[]);

  useEffect(() => {
    if (data && !isFetching) {   
      setQuery("");
      setPolling(true);
    }
  },[data, isFetching]);

  useEffect(() => {
    if (pollingData) {
      dispatch(addPollingResponse(pollingData));
      setNotifications(1); 
    }
  },[pollingData]);

  return {
    messages,
    polling,
    isFetching, 
    isLoading, 
    isResetting,
    handleQuery
  }
}

function addQuery(payload) {
  return { 
    type: MESSASE_ACTION_TYPES.query, 
    payload
  }
}

function addSystemMessage(payload) {
  return { 
    type: MESSASE_ACTION_TYPES.system, 
    payload
  }
}

function addResponse(payload) {
  return { 
    type: MESSASE_ACTION_TYPES.response, 
    payload
  }
}

function addPollingResponse(payload) {
  return { 
    type: MESSASE_ACTION_TYPES.polling, 
    payload
  }
}

function initQuery(payload) {
  return { 
    type: MESSASE_ACTION_TYPES.init, 
    payload
  }
}

export default useMessagePolling;