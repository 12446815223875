/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";

/**
 * TypedMessage
 * 
 * @param {String}    text
 * @param {Boolean}   animate
 */
const TypedMessage = ({ text, animate, onComplete }) => {
  const [message, setMessage] = useState("");
  const [count, setCount] = useState(-1);
  const [max, setMax] = useState(1000);
  const [messageParts, setMessageParts] = useState("");
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    if (hasPlayed && typeof onComplete === "function") {
      onComplete();
    };
  },[hasPlayed]);

  useEffect(() => {
    if (hasPlayed) return;

    let interval;
  
    if ((count !== -1) && (count < max) && animate) {
      interval = setInterval(() => setCount(c => c + 1), 300);
    } else if (count === max) {
      setHasPlayed(true);
    }
    return () => clearInterval(interval);
  },[max, count, animate, hasPlayed]);

  useEffect(() => {
    if (animate) {
      const parts = text.split(" ");

      setCount(0);
      setMessageParts(parts);
      setMax(parts.length - 1);
    }
  },[text, animate]);

  useEffect(() => {
    if (!animate && count > 0) {
      setHasPlayed(true);
    }
  },[animate, count]);

  useEffect(() => {
    if ((count === -1) || !messageParts || hasPlayed) return;

    if (messageParts[count]) {
      setMessage((prev) => `${prev} ${messageParts[count]}`);
    } 
  },[count, text, hasPlayed, messageParts])
  
  return hasPlayed  ? text : message;
}

export default TypedMessage;