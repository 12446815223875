/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useState, useEffect, useRef } from "react";
import { motion } from 'framer-motion';
import { MessageContainer } from "components/Messages";
import { useViewport, useBodyScroll, useAuth, useScrollDirection } from  "hooks";
import { WINDOW_HEIGHT, WINDOW_WIDTH } from "constants";
import useMessagePolling from "hooks/useMessagePolling";
import Header from "components/Header";
import Input from "components/Input";
import Product from "components/Product";
import Carousel from "components/Carousel";

/**
 * Container
 */
const Container = () => {
  const { scroll, init } = useScrollDirection();
  const [applyStyle, setStyle] = useState({});
  const [products, setProducts] = useState([]);
  const dimensions = window.bigSurSettings?.dimensions;
  const windowWidth = (dimensions && dimensions[0]) || WINDOW_WIDTH;
  const windowHeight = (dimensions && dimensions[1]) || WINDOW_HEIGHT;
  const inputContainer = useRef(null);
  const messagesEnd = useRef(null);
  const messagesContainer = useRef(null);
  const scrollToBottom = () => messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
  const [showWindow, setShowWindow] = useState(false);
  const { isMobile } = useViewport();
  const { userId, setNotifications } = useAuth();
  const { enableScroll, disableScroll } = useBodyScroll();
  const { messages, handleQuery } = useMessagePolling({ userId, showWindow });
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowWindow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (showWindow) {
      disableScroll();      
    } else {
      enableScroll();
    }
    
    if (showWindow) {
      setNotifications(0);
    }
  },[showWindow, disableScroll, enableScroll, setNotifications]);
  
  useEffect(() => {
    scrollToBottom();
  },[messages]);

  useEffect(() => {
    let pos = scroll.direction === 'up' ? ((scroll.marker - scroll.distance)/3) : (scroll.distance)/3;
    pos = pos < 0 ? 0 : pos;
    setStyle(`translateY(${Math.floor(pos)}px)`);
  },[scroll.direction, scroll.marker, scroll.distance]);
  
  useEffect(() => {
    if (showWindow) {
      init();
    }
  },[showWindow]);

  useEffect(() => {
    let tempProducts = [];

    messages.forEach(element => {
      if (element.meta) {
        tempProducts = [...tempProducts, ...element.meta]; 
      }
    });

    setProducts(tempProducts);
  },[messages])

  return (
    <>
      <motion.div css={styles.background} variants={animations.backdrop} animate={showWindow ? 'open': 'closed'} /> 
      <div css={styles.wrapper(windowWidth, windowHeight, isMobile, showWindow)}>
        <div ref={wrapperRef} css={styles.outer(windowWidth, windowHeight, isMobile, showWindow)}>
          <motion.div 
            css={styles.modal(showWindow, windowHeight, isMobile)} 
            variants={animations.modal} 
            animate={showWindow ? 'open': 'closed'}
            > 
            <Header 
              onClose={() => setShowWindow(false)}
              onReset={() => handleQuery("reset")}
              show={showWindow}
            />
            <div id="messageContainer" css={styles.container(showWindow)} ref={messagesContainer}>
              <MessageContainer 
                showWindow={showWindow} 
                data={messages} 
                onPromptClick={handleQuery}
              />   
              <div ref={messagesEnd} css={styles.message_end} />
            </div>
          </motion.div>
          <div ref={inputContainer} css={styles.action_container({isMobile, windowWidth, showWindow})} style={{ '--transform-style': applyStyle }} >
            <div>
              <Input 
                onClick={() => setShowWindow(true)}
                showWindow={showWindow} 
                onSubmit={handleQuery}
              />  
            </div>
          </div>
        </div>
      </div>     
      {/*!isMobile && (
        <motion.div css={styles.carousel(windowHeight)} variants={animations.carousel} animate={showWindow ? 'open': 'closed'}>
          {products?.length > 0 && (
            <Carousel count={products?.length}>
              {products?.map((product, index) => (
                <Product 
                  key={`${product.title}-${index}`} 
                  {...product} 
                />
              ))}
            </Carousel>
          )}
        </motion.div> 
      )*/}
    </>
  );
}

const animations = {
  modal: {
    open: {
      display: 'flex',
      visibility: 'visible',
      opacity: 1,
      transition: {
        delay: 0
      }
    },
    closed: {
      display: 'none',
      opacity: 0,
    }
  },
  backdrop: {
    open: {
      display: 'block',
      visibility: 'visible',
      opacity: 1,
    },
    closed: {
      display: 'none',
      opacity: 0,
    }
  },
  carousel: {
    open: {
      display: 'block',
      visibility: 'visible',
      opacity: 1,
      scale:1
    },
    closed: {
      display: 'none',
      opacity: 0,
      scale:0.9
    }
  }
};

const styles = {
  background: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.1);
    display:none;
  `,
  wrapper: (windowWidth, windowHeight, isMobile, showWindow) => css`
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - (${windowWidth} / 2) - 20px);
    max-width:100%;
    max-height:100%;
    box-sizing: border-box;
    transform-origin: bottom center;
    transform: scale(${showWindow ? '1' : '0.9'});
    transition: height 200ms ease 0s, transform 300ms cubic-bezier(0, 2, 1, 1) 0s, opacity 300ms ease-out 0s;

    @media only screen and (max-width: ${windowWidth}) {
      left:0;
      width:100%;
    }

    ${isMobile && `
      width:100%;
      left:0;
    `}

    ${showWindow && `
      height: ${showWindow && isMobile ? '100%' : showWindow ? windowHeight : 'auto'};
    `}
  `,
  outer: (windowWidth, windowHeight, isMobile, showWindow) => css`
    width: ${isMobile ? `100%` : windowWidth};
    max-width:100%;
    display:flex;
    align-items:center;
    flex-direction:column;
    margin: 0 auto;
    height: 100%;
    max-height:100%;
    padding: ${isMobile ? `0 10px` : `0 20px`};

    @media only screen and (max-width: ${windowWidth}) {
      width:100%;
    }

    &:before {
      content: " ";
      position: absolute;
      opacity:${showWindow ? '1' : '0'};
      top: 10px;
      left: 15px;
      right: 15px;
      bottom: 10px;
      border-radius:20px;
      background:#fff;
      z-index:-1;
      pointer-events:none;
      transform-origin: bottom center;
      transform: scale(${showWindow ? '1' : '0.8'});
      box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    }

    ${(isMobile || !showWindow) && `
      &:before {
        transition:none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: none;  
        border-radius:0;      
      }
    `}
  `,
  message_end: css`
    display:block !important;
  `,
  modal: (showWindow) => css`
    width: 100%;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    overflow: hidden;
  `,
  container: showWindow => css`
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    overflow: hidden scroll;
    box-sizing:border-box;
    margin: 0px auto 20px auto;
    display: inline-flex;
    flex-direction: column;
    opacity: ${showWindow ? `1` : `0`};
    transition: opacity 300ms ease;
    padding: 0 15px;

    ${!showWindow && `
      scrollbar-width: none; 
      &::-webkit-scrollbar {
        display:none;
      }
    `}
  `,
  action_container: ({isMobile, windowWidth, showWindow}) => css`
    display:flex;
    align-items:center;
    justify-content:center;
    transform: var(--transform-style);
    z-index: 10000;
    padding:0;
    width:100%;
    max-width:100%;
    box-sizing: border-box;
    height:50px;
    margin-bottom: ${isMobile ? `20px`: `30px`};
    padding: 15px;

    ${showWindow && `
      transform: none !important;
    `}

    > div {
      width:100%;
    }

    ${isMobile && `
      bottom:10px; 
      max-width:100%;
    `}
  `,
  carousel: windowHeight => css`
    position:fixed;
    left:0px;
    right:0px;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: top center;

    > div {
      width: auto;
    }

    @media only screen and (max-height: ${parseInt(windowHeight) + 280}px) {
      display:none !important;
    }
  `
}
export default Container;