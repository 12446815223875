/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { useState } from "react";
import { Message, TypedMessage } from ".";
import { motion, AnimatePresence } from "framer-motion";
import { DEFAULT_TEXT } from "constants";
import { color, fontSize } from "style";

/**
 * MessageContainer
 * 
 * @param {Boolean}   showWindow 
 * @param {Object}    data  
 */
const MessageContainer = ({ showWindow, data, onPromptClick }) => {
  const prompts = window.bigSurSettings?.prompts || DEFAULT_TEXT?.prompts;
  const [isFinishedTyping, setIsFinishedTyping] = useState([]);

  return (
    <div css={styles.container(showWindow)}>
      <div css={styles.version}>
        {`Big Sur ${process.env.REACT_APP_VERSION}`}
      </div>
      <AnimatePresence initial={false}>
        {data?.map((item, index) => {
          const isFirstAndOnlyMessage = (index === 0 && data?.length === 1);
          
          return (
            <motion.div
              key={item.id}
              positionTransition
              initial={{ opacity: 0, y: 10 }} 
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, transition: { duration: 0.15 } }}
            >
              <Message 
                {...item}
                onPromptClick={onPromptClick}
                message={
                  item.typed && isFirstAndOnlyMessage ? (
                    <TypedMessage 
                      text={item.message} 
                      animate={showWindow}
                      onComplete={() => setIsFinishedTyping((prev) => [...prev, item.id])}
                    />
                  ) : (
                    item.message 
                  )
                }
                isFinishedTyping={
                  (item.typed && isFirstAndOnlyMessage && isFinishedTyping.indexOf(item.id) > -1)
                } 
                isMe={item.isQuery}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

const styles = {
  version: css`
    color: ${color.secondaryText};
    margin-bottom:20px;
    font-size: ${fontSize.xsmall};
    margin-top:100px;
  `,
  container: show => css`
    width:100%;
    box-sizing:border-box;
    animation-delay: 1s;
    opacity: ${show ? '1' : '0'};
    display:flex;
    flex-direction:column;
    gap:15px;
    margin-top:auto;
    padding-bottom:10px;
    scrollbar-width: none; 

    &::-webkit-scrollbar {
      display:none;
    }

    > * {
      transition: all 300ms ease; 
      transform: scale(0.8);
    }

    ${show && `
      transition: all 300ms ease; 
      transition-delay: 200ms;

      > * {
        transform: scale(1);
        transition-delay: 200ms;        
      }
    `}
  `,
  typing: css`
    font-style: italic;
    color: ${color.secondaryText};
  `
}

export default MessageContainer;