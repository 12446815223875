/**
 * generateRandomString
 *
 * @param {Number} len 
 * 
 * @returns {string} the shorten string
 */
function generateRandomString(len) {
  var characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";  
  var randomstring = '';  

    //loop to select a new character in each iteration  
  for (var i=0; i<len; i++) {  
    var rnum = Math.floor(Math.random() * characters.length);  
    randomstring += characters.substring(rnum, rnum+1);  
  }  

  return randomstring;
}

function generateId() {
  
  return `${generateRandomString(5)}-${generateRandomString(5)}-${generateRandomString(5)}-${generateRandomString(5)}`

}

export { generateId };

export default generateRandomString;