/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { color, fontSize } from "style";

/**
 * SystemMessage
 */
const SystemMessage = () => {
  return (
    <div css={styles.container}>
      <div css={styles.line} />
      New Conversation
      <div css={styles.line} />
    </div>
  );
}

const styles = {
  container: css`
    display:flex;
    align-items:center;
    justify-content:center;
    font-size: ${fontSize.xsmall};
    color: ${color.secondaryText};
    position:relative;
    gap:10px;
    margin: 10px 0;
  `,
  line: css`
    display:block !important;
    border-top: 1px ${color.lightBorder} dotted;    
    flex:1;
  `
}

export default SystemMessage;