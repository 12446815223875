import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SERVER_ADDRESS, SOURCE } from '../constants';

export const bigSurApi = createApi({
  reducerPath: 'bigSurApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: SERVER_ADDRESS 
  }),
  endpoints: (builder) => ({
    getResultByString: builder.query({
      query: ({query, userId, id}) => `bpq?s=${SOURCE}&u=${userId}&q=${query}&i=${id}`,
    }),
    resetConversation: builder.query({
      query: (userId) => `qr?s=${SOURCE}&u=${userId}`,
    }),
    updateResult: builder.query({
      query: ({userId, id}) => `cpq?s=${SOURCE}&u=${userId}&i=${id}`,
    }),
  }),
});


//"https://bigsur.ngrok.io/bpq?s=" + shop + "&q=" + input + "&u=" + uid

export const { 
  useGetResultByStringQuery, 
  useResetConversationQuery,
  useUpdateResultQuery 
} = bigSurApi;