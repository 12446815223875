/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { color, fontSize } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import Menu from "components/Menu";
import { useViewport } from "hooks";

/**
 * Header
 * 
 * @param {Function} onClose 
 * @param {Function} onReset 
 */
const Header = ({ show, onClose, onReset }) => {
  const { isMobile } = useViewport(); 
  const items = [
    {
      label: "Reset conversation", 
      onClick: onReset
    }
  ];

  return (
    <div css={styles.container(show, isMobile)} style={{ '--opacity-style': show ? '1' : '0' }}>
      <button css={styles.back_button} onClick={onClose} role="button" aria-label="Close Window">
        <Icon type={ICON_TYPE.close} size={ICON_SIZE.xlarge} color={color.primaryText} />
      </button>
      <div css={styles.version}>
     
      </div>
      <Menu items={items}>
        <Icon type={ICON_TYPE.more} size={ICON_SIZE.large} color={color.primaryText} />
      </Menu>
    </div>
  );
}

const styles = {
  container: (show, isMobile) => css`
    display:flex;
    width:100%;
    box-sizing: border-box;
    justify-content:space-between;
    height:50px;
    align-items:center;    
    gap:10px;
    box-sizing:border-box;
    padding: ${isMobile ? ` 10px` : `0 15px`};  
    opacity: var(--opacity-style);
    transition: ${show ? `opacity 500ms linear` : `none`};  
    margin-top:${isMobile ? `0` : `5px`};  
  `,
  version: css`
    font-size: ${fontSize.xsmall};
    flex:1;
    text-align: right;
    color: ${color.secondaryText};
  `,
  back_button: css`
    background: none;
    border:0;
    display:flex;
    align-items:center;
    gap:5px;
    padding:0;
    font-size:${fontSize.xsmall};
    font-weight:600;
    color: ${color.secondaryText};
    cursor:pointer;
    transition: all 300ms ease;

    &:hover {
      transform: scale(1.2);
    }
  `
}

export default Header;