/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { motion, AnimatePresence } from "framer-motion";
import { boxShadow, color, fontSize } from "style";
import { ProductButton } from "components/Buttons";
import { useViewport } from "hooks";
import { ShortenText, formatCurrency } from "utils";

/**
 * Product
 */
const Product = ({ title, vtitle, price, href, image}) => {
  const { isMobile } = useViewport();
 
  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ y: 0, opacity:1, scale:1  }}
      transition={{ delay: 0, duration: 0.3,  }}
      css={styles.outer_container}
      >
      <a css={styles.link} href={href}>
        <div css={styles.container(isMobile)}>
          <div css={styles.image(image)}></div>
          <div css={styles.text_container}>
            <div className="product-title" css={styles.title}>
              {ShortenText(title, 30)}
            </div>
            {vtitle && (
              <div css={styles.subtitle}>
                {ShortenText(vtitle, 30)}
              </div>
            )}
            <div css={styles.footer}>
              <div css={styles.price}>
                {formatCurrency(price)}
              </div>
              <div>
                <ProductButton role="button" aria-label="View Product">
                  View
                </ProductButton>
              </div>
            </div>
          </div>
        </div>
      </a>
    </motion.div>
  );
}

const styles = {
  outer_container: css`
    align-self: stretch;
    margin-top:20px;
  `,
  container: isMobile => css`
    background:#fff;
    border-radius:15px;
    padding:5px;
    border:1px ${color.lightBorder} solid;
    width:200px;
    max-width:100%;
    position:relative;    
    box-sizing:border-box;
    height: 100%;
    display: flex;
    flex-direction: column;

    ${!isMobile && `
      &:hover {
        border:1px ${color.primary} solid;

        .product-title {
          color: ${color.primary};
        }
      }
    `}
  `,
  link: isMobile => css`
    color:inherit;
    text-decoration:none;
    cursor:pointer;
    display:inline-flex;
    transition: transform 300ms ease;
    height: 100%;
  `,
  image: href => css`
    background: url(${href}) 50% 50% no-repeat;
    background-size: cover;
    height:130px;
    width:100%;
    border-radius:10px 10px 4px 4px;
    display:block !important;
  `,
  text_container: css`
    padding:10px;
    display:flex;
    flex-direction:column;
    gap:2px;
    flex: 1;
  `,
  title: css`
    font-weight: 700;
    font-size: ${fontSize.medium};
    position:relative;
    max-width:85%;
    min-height:33px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-end;
    line-height: normal;
  `,
  subtitle: css`
    font-size: ${fontSize.xsmall};
    color: ${color.secondaryText};
  `,
  price: css`
    font-weight: 400;
    font-size: ${fontSize.normal};
  `,
  footer: css`
   display:flex;
   align-items:center;
   justify-content: space-between;
   margin-top:auto;
  `,
}

export default Product;
