/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useState } from "react";
import { css} from "@emotion/react"
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { color } from "style";
import { useViewport } from "hooks";

/**
 * Carouself
 * 
 * @param {Object}  children
 * @param {Number}  count
 * @param {Number}  productSize
 * @param {Boolean} polling
 */
const Carousel = ({ children, count, productSize, polling }) => {
  const scrollBox = useRef();
  const { isMobile } = useViewport();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const scroll = (scrollOffset) => scrollBox.current.scrollLeft += scrollOffset;
  const handleScroll = () => setScrollPosition(scrollBox.current.scrollLeft);
  const [total, setTotal] = useState(count);

  // Calculate start/end position
  useEffect(() => {        
    const maxScrollLeft = scrollBox.current.scrollWidth - scrollBox.current.clientWidth;
    setIsEnd(maxScrollLeft === scrollPosition);
    setIsStart(scrollPosition === 0);
  },[scrollPosition])

  // Listen for scroll position
  useEffect(() => {
    const el = scrollBox?.current;
    el?.addEventListener('scroll', handleScroll);
    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  },[]);

  useEffect(() => {
    setTotal(count);
  },[count]);

  useEffect(() => {
    if (total > 1 && polling && isLoaded) {
      scrollBox.current.scrollLeft = (productSize * total);     
    }
    setIsLoaded(true);
  },[total, polling, productSize, isLoaded])

  return (
    <div css={styles.outer_container(isMobile)}>
      <div ref={scrollBox} css={styles.container}>
        <div css={styles.inner_container(count, productSize, isMobile)}>
          {children}
        </div>
      </div>
      <div css={[styles.icon, styles.icon_right(polling || isEnd)]} onClick={() =>scroll(productSize)}>
        <Icon size={ICON_SIZE.xlarge} type={ICON_TYPE.rightarrow} color={color.primaryText } />
      </div>
      <div css={[styles.icon, styles.icon_left(polling || isStart)]} onClick={() =>scroll((-1 * productSize))}>
        <Icon size={ICON_SIZE.xlarge} type={ICON_TYPE.rightarrow} color={color.primaryText } />
      </div>
    </div>
  );
}

const styles = {
  container: css`
    width:100%;
    overflow-x:auto;
    position:relative;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    scroll-behavior: smooth; 
    
    &::-webkit-scrollbar {
      display:none;
    }
  `,
  outer_container: isMobile => css`
    width: ${isMobile ? `calc(100vw - 50px)` : `100%`};
    position:relative;
    overflow:hidden;
  `,
  inner_container: (count, productSize, isMobile) => css`
    width: ${(count * productSize) + (count * 10)}px;
    min-width:100%;
    display:flex;
    justify-content:${isMobile ? `flex-start` : `flex-start`};
    gap:10px;
  `,
  icon: css`
    width:50px;
    height:50px;
    background:rgba(255,255,255,0.9);
    border-radius:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    top: calc(50% - 25px);
    position:absolute;
    transition: opacity 300ms ease;
  `,
  icon_right: isEnd => css`
    right:-20px;
    opacity: ${isEnd ? 0 : 1};
  `,
  icon_left: isStart => css`
    left:-20px;
    transform: rotate(180deg);
    opacity: ${isStart ? 0 : 1};
  `,
  message_end: css`
    display:block !important;
  `,
}

Carousel.defaultProps = {
  productSize: 210
}

export default Carousel;
