/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css} from "@emotion/react";
import { motion, AnimatePresence } from "framer-motion";
import { color, fontSize } from "style";
import moment from "moment";
import Product from "components/Product";
import Carousel from "components/Carousel";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { MOBILE_CUTOFF } from "constants";
import { SuggestionMessage, SystemMessage } from "components/Messages";
import { MESSAGE_TYPES } from "constants";
import Cursor from "components/Cursor";
import { useViewport } from "hooks";

/**
 * Message
 * 
 * @param {String}    id
 * @param {String}    type
 * @param {Object}    message
 * @param {Object}    meta
 * @param {String}    date
 * @param {Boolean}   isMe
 * @param {Boolean}   isFetching
 * @param {Boolean}   isInterrupted
 */
const Message = ({ 
  id, 
  type, 
  message, 
  meta, 
  date, 
  prompts,
  onPromptClick,
  isMe, 
  isFetching, 
  isInterrupted, 
  isFinishedTyping 
}) => {
  const [products, setProducts ] = useState(meta);
  const { isMobile } = useViewport();

  useEffect(() => {
    setProducts(meta);
  },[meta]);

  if (type === MESSAGE_TYPES.system) {
    return (
      <SystemMessage />
    );
  }
  
  return (
    <>
      <div css={styles.container(isMe)} data-id={id}>
        <span>
          <span>{message}</span>          
          {isFetching && <Cursor />}
        </span>
        {!isMe && (
          <div css={styles.date_time_container}>    
            {isFetching ? (
              <div css={styles.typing}>
                {!message && `One moment please...`}
                {message && isFetching && `Typing...`}
              </div>
            ) : (
              <div css={styles.date_time}>
                {!isMe && (
                  <>
                    <Icon 
                      type={isInterrupted ? ICON_TYPE.interruption : ICON_TYPE.clock} 
                      size={ICON_SIZE.small} 
                      color={color.secondaryText} 
                    />        
                    {moment(date).format('h:mm A')}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>     
      {products?.length > 0 && (
        <Carousel count={products?.length} polling={isFetching}>
          {products?.map((product, index) => (
            <Product 
              key={`${message.id}-${index}`} 
              {...product} 
            />
          ))}
        </Carousel>
      )}
      {prompts?.length > 0 && isFinishedTyping && !isFetching && (
        <div css={styles.prompts}>
          {prompts?.map((prompt, index) => (
            <SuggestionMessage  
              key={index}
              message={prompt}
              onClick={onPromptClick} 
            />
          ))}
        </div>
      )}
    </>
  );
}

const styles = {
  container: (isMe) => css`
    font-family: "PT Serif", Georgia;
    box-sizing:border-box;
    max-width:100%;
    font-size: ${fontSize.normal};
    white-space: pre-line;
    display:flex;
    flex-direction:column;
    gap:3px;
    color: ${color.primaryText};

    @media only screen and (max-width: ${MOBILE_CUTOFF}px) {
      font-size: ${fontSize.normal};
    }

    @media only screen and (min-width: ${MOBILE_CUTOFF}px) {
      font-size: ${fontSize.normal};
    }

    ${isMe && `
      font-size: ${fontSize.medium};
      font-weight:700;
    `}
    
    ${!isMe && `
      margin-right:auto;
      font-weight:400;
    `}
  `,
  prompts: css`
    display:flex;
    flex-direction:column;
    gap:5px;
    justify-content:flex-start;
    margin-top:15px;
  `,
  typing: css`
    font-style: italic;
  `,
  date_time_container: css`
    display:flex;
    align-items:center;
    justify-content: space-between;
    font-size: ${fontSize.xsmall};
    color: ${color.secondaryText};
    opacity: 0.7;
    margin-top:0.25rem;
    font-family: "Open Sans"
  `,
  date_time: css`
    display:flex;
    align-items:center;
    gap:5px;
    margin-righ:auto;
  `,
  interrupted: css`
    position: absolute;
    background: #fff;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    bottom: -9px;
    left: -9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px #fff solid;
  `,
}

export default Message;