const SERVER_ADDRESS = "https://bigsur.ngrok.io";
const WINDOW_WIDTH = '520px';
const WINDOW_HEIGHT = '520px';
const SOURCE = 'acw-store-1.myshopify.com';
const USER = '123';
const MOBILE_CUTOFF = 700;
const POLLING_INTERVAL = 50;
const IDLE_MINUTES = 1200;

const DEFAULT_TEXT = {
  title: "Welcome to Foot Forward.",
  subtitle: "How can I assist you today?",
  placeholder: "Ask me anything...",
  prompts: [
    "I need running shoes for a marathon",
    "I'm looking for cheap and sturdy basketball shoes",
    "Do you have tennis shoes that Nadal wears?"
  ]
};

const MESSAGE_VALUES = {
  reset: "reset",
  continuePolling: "False",
}

const MESSAGE_TYPES = {
  message: "message",
  system: "system",
}

const MESSASE_ACTION_TYPES = {
  init: "INIT",
  query: "QUERY",
  response: "RESPONSE",
  polling: "POLLING",
  system: "SYSTEM"
}

export { 
  SERVER_ADDRESS, 
  WINDOW_WIDTH, 
  WINDOW_HEIGHT, 
  SOURCE, USER, 
  DEFAULT_TEXT, 
  MOBILE_CUTOFF, 
  POLLING_INTERVAL,
  MESSAGE_TYPES,
  MESSAGE_VALUES,
  MESSASE_ACTION_TYPES,
  IDLE_MINUTES
};