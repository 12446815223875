import useLocalStorage from "hooks/useLocalStorage";
import React, { createContext, useContext, useEffect, useState } from "react";
import { generateRandomString } from "utils";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [userId, setUserId] = useLocalStorage('userId', generateRandomString(64));
  const [notificationsCount, setNotifications] = useState(0);

  useEffect(() => {
    setUserId(userId);
  },[]);

  return (
    <AuthContext.Provider value={{ userId, notificationsCount, setNotifications }}>
      {children}
    </AuthContext.Provider>
  ); 
}

export function useAuth() {
  return useContext(AuthContext);
}