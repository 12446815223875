/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"

/**
 * Loader
 */
const Loader = ({ isLoading }) => <span css={styles.container(isLoading)} />

const styles = {
  container: isLoading => css`
    font-weight: 100;
    font-size: 30px;
    background: #000;
    animation: ${isLoading ? ` 2s blinkspin infinite ease` : `1s blink step-end infinite`};
    width: 1px;
    height: 22px;
    display: inline-block;
    position: relative;
    margin-bottom: -5px;
    margin-left: 0;
  `,
}

export default Loader;