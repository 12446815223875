/** @jsxImportSource @emotion/react */
import { css} from "@emotion/react"
import { useState } from "react";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { useViewport } from  "hooks";
import { boxShadow, color, fontSize } from "style";
import { DEFAULT_TEXT } from "constants";
import Notification from "components/Notification";

/**
 * Input
 * 
 * @param {Boolean}     showWindow 
 * @param {Function}    onClick 
 * @param {Function}    onSubmit 
 */
const Input = ({ showWindow, onClick, onSubmit }) => {
  const [isFocus, setFocus] = useState(showWindow);
  const [value, setValue] = useState("");
  const handleChange = e => setValue(e.target.value);
  const { isMobile } = useViewport();
  const placeHolder = window.bigSurSettings?.placeholder || DEFAULT_TEXT?.placeholder;
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(value);
    setValue("");
  }

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.outer_container}>
        <div css={styles.input_container(showWindow, isFocus)} onClick={onClick}>
          <input 
            type="text" 
            //disabled={isMobile && !showWindow}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={value}
            onChange={handleChange}
            css={styles.input(isFocus)}
            placeholder={placeHolder}
          />
          <div  css={styles.icon_container}>
            <button css={styles.button} disabled={!value} type="submit" role="button" aria-label="Send">
              <Icon 
                size={ICON_SIZE.large} 
                type={ICON_TYPE.send} 
                color={(!isFocus || value) ? `#fff` : `rgba(255,255,255,0.3)`}
              />
            </button>
          </div>        
          <Notification 
            css={styles.notification(showWindow)} 
            showWindow={showWindow}
          />
        </div>         
      </div>
    </form>
  );
}

const styles = {
  outer_container: css`
    position:relative;
  `,
  input_container: (show, isFocus) => css`
    position:relative;
    display:flex;
    justify-content: space-between;
    border-radius: 14px;
    background: rgba(255,255,255,0.8);
    transform-origin: bottom center;
    transform: scale(${show ? '1' : '1'});
    transition: all 300ms ease;
    border:1px ${isFocus ? color.primary : color.lightBorder} solid;

    ${!show && `cursor:pointer;`}

    ${isFocus && `
      outline:none;
      background:rgba(255,255,255,1);
      box-shadow: ${boxShadow.formElementFocus};   
    `}
  `,
  notification: show => css`
    position:absolute;
    right:-7px;
    top:-7px;
    transform: scale(1.2);
  `,
  input: isFocus => css`
    box-sizing:border-box;
    outline: none;
    border-width: 0px;
    transition: all 0.3s ease;
    border-radius: 13px 0 0 13px;
    border-right-width:0;
    padding:10px;
    height:50px;
    resize: none;
    overflow-y: hidden;
    flex:1;
    background:rgba(255,255,255,0.8);
    opacity: 1;
    // Font size must be at least 16px to prevent iOS zoom
    //(https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone)
    font-family: "PT Serif", Georgia;
    font-size: ${fontSize.normal};
    color: ${color.primaryText};
    box-shadow: none !important;
    
    &::placeholder {
      color:${color.placeholder};
    }
  `,
  icon_container: css`
  `,
  button: css`
    background:${color.primary};
    border:0;
    outline:none;
    height:50px;
    width:50px;
    border-radius: 0 13px 13px 0;

    &:disabled {
      color: rgba(255,255,255,0.2);
    }
  `
}

export default Input;