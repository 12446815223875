const color = {
  lightBorder: "#e3e5ee",
  darkBorder: "#c6c8d2",
  primaryText: "#2f3039",
  secondaryText: "#8b899b",
  secondaryBackground: "#f3f3f3",
  tertiaryBackground: "#9696cb",
  primary: "#5869e8",
  white: "#fff",
  placeholder: "#b8adbb",
  disabledText: "#dadee5",
  red: "#f12c2c"
}

const boxShadow = {
  formElement: "0px 0px 6px rgba(176,132,214,0.2)",
  formElementFocus: "0px 0px 0px 4px rgba(88,105,232,0.2)",
  enhance: "0px 2px 4px rgba(176,132,214,0.2)",
}

const fontSize = {
  xxsmall: "7px",
  xsmall: "10px",
  small: "13px",
  medium: "14px",
  normal: "16px",
  large: "20px",
  xlarge: "24px"
};

export { color, boxShadow, fontSize };